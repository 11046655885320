import { useCallback } from "react";
import useMainStore from "./ReduxHooks/mainStore";

export const useMicrophonePermissions = () => {
  // Redux store
  const { setUnsupportedMicrophonePermissionModalData } = useMainStore();

  const checkMicrophonePermissions = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setUnsupportedMicrophonePermissionModalData({ show: false });
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      const unsupportedModalData = {
        show: true,
      };
      setUnsupportedMicrophonePermissionModalData(unsupportedModalData);
    }
  }, [setUnsupportedMicrophonePermissionModalData]);

  return {
    checkMicrophonePermissions,
  };
};
