import { CONTACT, GROUP } from "./constants";
import { UNICODE_REGEX } from "./rejexConstant";
import { isNotAnEmptyArray } from "./settingsHelpers";

export const getScheduledThreadsCounts = (isNumbersFiltered, threads) => {
  const {
    scheduledTotal,
    scheduledAllNums,
    scheduledContactsTotal,
    scheduledContactsAllNums,
    scheduledGroupsTotal,
    scheduledGroupsAllNums,
    scheduledGroupsUnnamed,
    scheduledGroupsUnnamedAllNums,
    scheduledGroupsNamed,
    scheduledGroupsNamedAllNums,
  } = threads;

  return {
    scheduledTotal: isNumbersFiltered ? scheduledAllNums : scheduledTotal,
    scheduledContactsTotal: isNumbersFiltered
      ? scheduledContactsAllNums
      : scheduledContactsTotal,
    scheduledGroupsTotal: isNumbersFiltered
      ? scheduledGroupsAllNums
      : scheduledGroupsTotal,
    scheduledGroupsUnnamed: isNumbersFiltered
      ? scheduledGroupsUnnamedAllNums
      : scheduledGroupsUnnamed,
    scheduledGroupsNamed: isNumbersFiltered
      ? scheduledGroupsNamedAllNums
      : scheduledGroupsNamed,
  };
};

export const getThreadsErrorMessage = (threadsError) => {
  let errorMessage = "An error occurred while loading threads.";
  const { status, response } = threadsError || {};
  if (status >= 400 && response?.data?.message) {
    errorMessage = response.data.message;
  }
  return errorMessage;
};

export const filterBlockedContacts = (
  contacts = [],
  blockedContactIds = []
) => {
  return contacts.filter((contact) => {
    const splitItemKey = contact?.item_key?.split("-");
    let id = "";
    if (splitItemKey?.length > 1) {
      id = splitItemKey[1];
    }
    return !blockedContactIds.includes(Number(id));
  });
};

export const getArchiveAndNonArchivedContactsFromSearchResults = (
  contacts = {},
  items = [],
  blockedContactIds = []
) => {
  const archivedContacts = [];
  const nonArchivedContacts = [];
  const contactsArray = Object.values(contacts);
  const filteredArchiveContacts = contactsArray
    .filter((contact) => Number(contact?.is_archived) === 1)
    .map((contact) => contact?.id);
  items.forEach((item) => {
    const splitItemKey = item?.item_key?.split("-");
    let id = "";
    if (splitItemKey?.length > 1) {
      id = splitItemKey[1];
    }
    if (
      filteredArchiveContacts.includes(Number(id)) ||
      item?.group?.is_archived === 1
    ) {
      archivedContacts.push(item);
    } else {
      nonArchivedContacts.push(item);
    }
  });
  return {
    archivedContacts,
    nonArchivedContacts: filterBlockedContacts(
      nonArchivedContacts,
      blockedContactIds
    ),
  };
};

export const getBlockedContactsFromSearchResults = (blockedContactIds = []) => {
  const blockedContacts = [];
  blockedContactIds.forEach((contacts_id) => {
    let contact = {
      key: contacts_id,
      item_key: "contact-" + contacts_id,
      contacts_ids: [contacts_id],
      withIntegrationIcon: true,
    };
    blockedContacts.push(contact);
  });
  return blockedContacts;
};

export const getPersonOrPeopleByCount = (count) => {
  if (count === 0) return "";
  if (count === 1) return "person";
  return "people";
};

export const getGroupOrGroupsTextByCount = (count) => {
  if (count === 0) return "";
  if (count === 1) return "group";
  return "groups";
};

export const getHasOrHaveByCount = (count) => {
  if (count === 0) return "";
  if (count === 1) return "has ";
  return "have ";
};

export const getMessageHint = (threadType, contactFirstName) => {
  if (
    threadType === CONTACT &&
    contactFirstName &&
    contactFirstName !== "Unnamed/Empty"
  ) {
    return `Hi ${contactFirstName}`;
  } else if (threadType === GROUP) {
    return `Hi [First Name]`;
  }
  return "";
};

export const arrayContainsActiveFilter = (array = [], activeFilter = "") => {
  if (!isNotAnEmptyArray(array)) return false;
  return array.some((filter) => activeFilter && activeFilter.includes(filter));
};

export const filterUnicodeCharacters = (str = "") => {
  const unicodeCharacters = [];

  // Define a regular expression to match specific characters

  for (const char of str) {
    const code = char.charCodeAt(0);

    // Check if the character matches the regex or its charCode is above 255
    if (code > 255 || char.match(UNICODE_REGEX)) {
      unicodeCharacters.push(char);
    }
  }

  return unicodeCharacters;
};

export const FILTERS_TO_SHOW_CLOSE_BUTTON_ON_TEXT_MESSAGE_TAB = [
  "unread",
  "undelivered-unread",
  "closed",
];
export const FILTERS_TO_SHOW_CLOSE_BUTTON_ON_VOICE_TAB = [
  "missed-unread",
  "voicemail-unread",
  "undelivered-unread",
  "closed",
];
