import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDuplicateViewInfo, checkIfSyncWayIsOneWay } from "../../helpers";
import SpanWithTooltip from "../SpanWithTooltip";
import { groupName as createGroupName } from "../../helpers";
import useGroupsStore from "../../utils/hooks/ReduxHooks/groupsStore";

import { grouprhsItemsSelected } from "../../actions/contacts";
import {
  getDataPayload,
  checkProgress,
  checkGroupProgress,
  getUrl,
  getFromPrefix,
  getClassName,
  getDisplayName,
  getGroupNameCountForHover,
  getContactForHover,
  getTooltip,
  getTextMessage,
  closeRHSAllowed,
} from "./ThreadItems/threadItemHelper";
import { UserMainMenuLookEnums } from "../../utils/enums/usersEnums";
import ThreadItemActionMessage from "./ThreadItems/ThreadItemActionMessage";
import ThreadItemActionWorkFlow from "./ThreadItems/ThreadItemActionWorkFlow";
import ThreadItemClickableWrapper from "./ThreadItems/ThreadItemClickableWrapper";
import ThreadItemAvatar from "./ThreadItems/ThreadItemAvatar";
import ThreadItemTitle from "./ThreadItems/ThreadItemTitle";
import ThreadItemMessageContent from "./ThreadItems/ThreadItemMessageContent";
import ThreadItemActionIcon from "./ThreadItems/ThreadItemActionIcon";
import { toggleGroupFromList } from "../../actions/groups";
import OneWayIntegrationSyncBadge from "./HubThreadGroupsComponents/HubThreadGroupsIntegrationsInfo/OneWayIntegrationSyncBadge";
import { CONTACT, GROUP } from "../../utils/constants";
import { useWithMobileMode } from "../../utils/hooks/useWithMobileMode";
import ThreadItemGroupSync from "./ThreadItems/ThreadItemGroupSync";
import useBreakpoint from "../../utils/hooks/useBreakpoints";
import { isMdOrBelowBreakpoint } from "../../utils/breakpoints";
import ThreadItemIconsContainer from "./ThreadItems/ThreadItemIconsContainer";
import useUsersStore from "../../utils/hooks/ReduxHooks/userStore";
import useMessagesStore from "../../utils/hooks/ReduxHooks/messagesStore";
import ThreadItemDuplicateContactsProfile from "./ThreadItems/ThreadItemDuplicateContactsProfile";

function mapStateToProps(store, ownProps) {
  let contacts = [];
  if (ownProps.newContact) {
    contacts.push(ownProps.newContact);
  } else if (
    ownProps.item_key.substr(0, 7) === CONTACT &&
    ownProps.contacts_ids &&
    ownProps.contacts_ids.length
  ) {
    if (
      (typeof ownProps.doNotGroup === "undefined" || !ownProps.doNotGroup) &&
      typeof store.contacts.data[ownProps.contacts_ids[0]] !== "undefined" &&
      store.contacts.data[ownProps.contacts_ids[0]]?.primary_contact_id > 0 &&
      store.contacts.data[ownProps.contacts_ids[0]]
        ?.multiple_primary_contacts === 0 &&
      typeof store.contacts.data[
        store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
      ] !== "undefined" &&
      store.contacts.data[
        store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
      ]
    ) {
      contacts.push(
        store.contacts.data[
          store.contacts.data[ownProps.contacts_ids[0]].primary_contact_id
        ]
      );
    } else {
      for (let i = 0; i < ownProps.contacts_ids.length; i++) {
        if (
          typeof store.contacts.data[ownProps.contacts_ids[i]] !==
            "undefined" &&
          store.contacts.data[ownProps.contacts_ids[i]]
        ) {
          contacts.push(store.contacts.data[ownProps.contacts_ids[i]]);
        }
      }
    }
  }

  let group = ownProps.group;
  if (!ownProps?.withOwnGroupData) {
    if (group && typeof store.groups.data[group.id] !== "undefined") {
      group = store.groups.data[group.id];
    } else if (ownProps.item_key.substr(0, 5) === GROUP) {
      const groupId = parseInt(ownProps.item_key.substr(6));
      if (typeof store.groups.data[groupId] !== "undefined") {
        group = store.groups.data[groupId];
      }
    }
  }
  return {
    userTimeZoneOffset: store.users.loggedUser.timezone_offset,
    contacts: contacts,
    group: group,
    loggedUser: store.users.loggedUser, //@todo remove it, tmp only
    unarchivingIds: store.contacts.unarchivingIds,
    archivingIds: store.contacts.archivingIds,
    archivingGroupIds: store.groups.archivingIds,
    unarchivingGroupIds: store.groups.unarchivingIds,
    deletingIds: store.groups.deletingIds,
    closingIds: store.threads.closingIds,
    reopeningIds: store.threads.reopeningIds,
    toggleSelected: store.threads.toggleSelected,
    contactDeletingIds: store.contacts.contactDeletingId,
    batchDeleteIds: store.contacts.batchDeleteIds,
    itemKey: store.threads.markAsReadItemId,
    threadFilter: store.threads.filter,
    voiceFilter: store.threads.voiceFilter,
    contactFilter: store.contacts.filter,
    checkedArchivedButton: store.main.checkedArchivedButton,
    searchedQuery: store.threads.searchedQuery,
    userPermissions: store.users.permissions,
    currentCompany: store.companies.currentCompany,
    loggedUserRoles:
      store.users.loggedUser.roles[store.companies.currentCompany.id],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    grouprhsItemsSelected: (selection) =>
      dispatch(grouprhsItemsSelected(selection)),
    saveIdsForModal: (selectedThreads) =>
      dispatch(toggleGroupFromList(true, selectedThreads)),
  };
}

const ThreadItem = (props) => {
  const {
    // Component props
    selected,
    handleSelected,
    selectMenuActive,
    hideHoverEffect,
    isSearchedThread,
    boldText,
    disableAction = false,
    groupKeyword,
    onClickViewHiddenSearchResult,
    thredItemNewContactPermissions,
    onClickNew,
    lhsGroupSelected,
    withThreadItemMessageContent,
    withThreadDate,
    withoutTooltip,
    withLabelAndOrangeStatusIcon,
    isActive,
    isAlreadyAdded,
    isAlreadyAddedButHidden,
    withNewDuplicateContactView,
    newContactType,

    // Redux props
    searchedQuery,
    toggleSelected,
    userPermissions,
    currentCompany,
    //Router
    history,
  } = props;

  // Redux hooks
  const { setPrefillIntegrationGroup } = useGroupsStore();
  const { toggleFullView, updateMainMenuLookOnMessages } = useUsersStore();
  const {
    messages: { isSendMessageWizard },
    toggleSendMessageWizard,
  } = useMessagesStore();

  //new
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [checkBoxActive, setCheckBoxActive] = useState(false);
  const [isHideCheckbox, setIsHideCheckbox] = useState(false);

  const [showNewContactActionTooltip, setShowNewContactActionTooltip] =
    useState(false);

  const breakpoint = useBreakpoint();
  const isMobile = useMemo(
    () => isMdOrBelowBreakpoint(breakpoint),
    [breakpoint]
  );

  //old
  const [archiveThis, setArchiveThis] = useState(false);
  const [unarchiveThis, setUnarchiveThis] = useState(false);
  const [deleteThis, setDeleteThis] = useState(false);
  const [closeThis, setCloseThis] = useState(false);
  const [reopenThis, setReopenThis] = useState(false);
  const [iconTooltip, setIconTooltip] = useState(false);

  const [unreadCountLocal, setUnreadCountLocal] = useState(props.unread_count);

  const isOnMobileMode = useWithMobileMode();
  const itemRef = props.itemRef ? props.itemRef : undefined;

  useEffect(() => {
    setUnreadCountLocal(props.unread_count);
  }, [props.unread_count]);

  const { filter } = props.match.params;
  let archivalProgress = false,
    unArchivalProgress = false,
    deletionProgress = false,
    closingProgress = false,
    reopeningProgress = false,
    contactDeleteProgress = false,
    batchDeleteProgress = false;

  const toggleCheckbox = (val) => () => {
    setShowCheckBox(val);
  };

  const toggleCheckboxState = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCheckBoxActive(!checkBoxActive);
    handleSelected(props.contacts[0]);
    if (toggleSelected === 0 && !isMobile && tab !== "groups")
      history.push(url);
  };

  if (props.group) {
    archivalProgress = checkGroupProgress(props.archivingGroupIds, props.group);
    unArchivalProgress = checkGroupProgress(
      props.unarchivingGroupIds,
      props.group
    );
    deletionProgress = checkGroupProgress(props.deletingIds, props.group);
    closingProgress =
      checkGroupProgress(props.closingIds, props.group) || closeThis;
    reopeningProgress =
      checkGroupProgress(props.reopeningIds, props.group) || reopenThis;
  } else {
    archivalProgress = checkProgress(props.archivingIds, props.contacts);
    unArchivalProgress = checkProgress(props.unarchivingIds, props.contacts);
    closingProgress =
      checkProgress(props.closingIds, props.contacts) || closeThis;
    reopeningProgress =
      checkProgress(props.reopeningIds, props.contacts) || reopenThis;
    contactDeleteProgress = checkProgress(
      props.contactDeletingIds,
      props.contacts
    );
    batchDeleteProgress = checkProgress(props.batchDeleteIds, props.contacts);
  }

  const isAllFlowsInProgress =
    archivalProgress &&
    unArchivalProgress &&
    deletionProgress &&
    closingProgress &&
    reopeningProgress;

  const isAnyFlowInProgress =
    archivalProgress ||
    unArchivalProgress ||
    deletionProgress ||
    closingProgress ||
    reopeningProgress;

  useEffect(() => {
    if (selected !== checkBoxActive) {
      setCheckBoxActive(selected);
    }
  }, [checkBoxActive, selected]);

  useEffect(() => {
    if (!selected && toggleSelected < 1) {
      setShowCheckBox(false);
    }
  }, [selected, toggleSelected]);

  useEffect(() => {
    const refCopy = detailRef.current;
    if (!Boolean(iconTooltip) && itemTooltipProps && detailRef.current) {
      window.$(detailRef.current).tooltip("dispose");
      window.$(detailRef.current).tooltip();
    }
    return () => {
      window.$(refCopy).tooltip("dispose");
    };
  });

  const tab = props.tab ? props.tab : "messages";

  const { isSingleMemberDuplicateRestArchived, singleUnArchivedContact } =
    useMemo(() => {
      return getDuplicateViewInfo(props.contacts);
    }, [props.contacts]);

  const isNewGroup = props.isNewGroup;
  const isGroup = props.item_key.indexOf("group-") >= 0;
  const isGroupTag = props.item_key.indexOf("tag-") >= 0;

  const checkedArchivedButtonRedux = singleUnArchivedContact
    ? props.checkedArchivedButton[singleUnArchivedContact.id]
    : false;

  const isAdhocGroup = isGroup && props.group?.addhoc_id > 0;
  const isAllPeopleGroup = isGroup && props.group?.my_group_status > 0;
  let isMultipleContacts =
    !isGroup &&
    !isGroupTag &&
    props.contacts &&
    props.contacts.length > 1 &&
    (!isSingleMemberDuplicateRestArchived || checkedArchivedButtonRedux);
  const isImportedHistory =
    isGroup && !isGroupTag && props.group.group_type === 10;

  /* moved to helper file */
  const { name, phoneNumber, isContactUpdating, groupName } = getDisplayName({
    isNewGroup,
    isGroup,
    isTag: isGroup && isGroupTag,
    group: props.group,
    singleUnArchivedContact,
    checkedArchivedButtonRedux,
    newGroupName: props.groupName,
    contact: props.contact,
    contacts: props.contacts,
    purpose: props.purpose,
    withNewDuplicateContactView,
    isMultipleContacts,
  });

  /* moved to helper file */
  const { withGroupMembersCount, groupMembersCount, showGroupFullNameOnHover } =
    getGroupNameCountForHover({
      isGroup,
      groupName,
      tab,
      group: props.group,
    });

  /* moved to helper file */
  const contactTooltip = getContactForHover({
    name,
    phoneNumber,
    isContactUpdating,
    isNewGroup,
  });

  let itemTooltipProps = getTooltip({
    iconTooltip,
    isGroup,
    showGroupFullNameOnHover,
    groupMembersCount,
    phoneNumber,
    isContactUpdating,
    name,
    contactTooltip,
    singleUnArchivedContact,
    checkedArchivedButtonRedux,
    contacts: props.contacts,
    withoutTooltip,
  });

  const withoutLog =
    props.handleNewContact ||
    props.purpose === "call-modal" ||
    props.withoutLog;
  const log = withoutLog ? null : props.log;
  const groupSmsBlast = withoutLog ? null : props.group_sms_blast;
  const scheduleMessage = withoutLog ? null : props.schedule_message;

  let isEmail = null;
  let msgType = "text";
  let route = "outbox";
  let duration = 0;
  let textMessage = "";
  let isImage = false;
  let emailSubject = null;
  let createdOrSendOnUtc = null;
  if (log) {
    isEmail = log.is_email;
    msgType = log.msg_type;
    route = log.route;
    duration = log.duration;
    textMessage = log.text_message;
    emailSubject = props.log.email_subject;
    createdOrSendOnUtc = log.created_utc;
    isImage = props.log.image_url !== "";
    if (log.group_broadcast_id > 0) {
      textMessage = log.broadcast_text_message;
    }
  } else if (groupSmsBlast) {
    msgType = groupSmsBlast.is_voice === 1 ? "broadcast" : "text";
    textMessage = groupSmsBlast.msg;
    if (props.voice_message) {
      msgType = "broadcast";
      textMessage = props.voice_message.text_message;
    }
    createdOrSendOnUtc = groupSmsBlast.created_utc;
  } else if (scheduleMessage) {
    if (scheduleMessage.msg_type === 2) {
      textMessage = scheduleMessage.mms_text;
      isImage = scheduleMessage.message !== "";
    } else {
      textMessage = scheduleMessage.message;
    }
    createdOrSendOnUtc = scheduleMessage.send_on_utc;
  } else if (groupKeyword) {
    textMessage = groupKeyword;
  }

  const { text, icon } = getTextMessage({
    isEmail,
    msgType,
    duration,
    textMessage,
    route,
    isImage,
    emailSubject,
    groupKeyword,
  });

  /* moved to helper file */
  let url = getUrl({
    isGroup,
    log,
    tab,
    isGroupTag,
    singleUnArchivedContact,
    isSingleMemberDuplicateRestArchived,
    group: props.group,
    contacts_ids: props.contacts_ids,
    purpose: props.purpose,
    params: props.match.params,
    isOnMobileMode,
  });

  let fromPrefix = getFromPrefix({
    route,
    user: props.user,
    loggedUser: props.loggedUser,
  });

  const secondRow =
    log || props.withContactDetails || text || props.undelivered_count > 0;

  let style = props.style ? Object.assign({}, props.style) : {};

  /* moved to helper file */
  const className = getClassName({
    unarchiveThis,
    deleteThis,
    isAllFlowsInProgress,
    isAnyFlowInProgress,
    secondRow,
    style,
    purpose: props.purpose,
    className: props.className,
    handleNewContact: props.handleNewContact,
    unread_count: unreadCountLocal,
    isActive: props.isActive,
    withContactDetails: props.withContactDetails,
    withNewDuplicateContactView:
      withNewDuplicateContactView && isMultipleContacts,
  });

  const contact =
    props.contacts && props.contacts.length
      ? props.contacts[0]
      : props.contact || null;

  const isMultiplePrimaryContacts =
    props.contacts &&
    props.contacts.length > 1 &&
    (!isSingleMemberDuplicateRestArchived || checkedArchivedButtonRedux); //contact && contact.multiple_primary_contacts === 1 ? true : false;

  const detailRef = React.createRef();
  const detailVisible =
    props.purpose !== "new-call-modal" ||
    (!props.withContactDetails &&
      (text || props.undelivered_count > 0 || unreadCountLocal > 0)) ||
    (props.withContactDetails && contact);

  const onArchiveToggle = (val) => () => {
    setArchiveThis(val);
  };

  const onUnarchiveToggle = (val) => () => {
    setUnarchiveThis(val);
  };

  const onDeleteToggle = (val) => () => {
    setDeleteThis(val);
  };

  const onCloseToggle = (val) => () => {
    setCloseThis(val);
  };

  const onReopenToggle = (val) => () => {
    setReopenThis(val);
  };

  const handleArchiveOrClose = (e) => {
    e.preventDefault();
    const { saveIdsForModal } = props;
    let action;
    if (tab === "messages" || tab === "calls") {
      action = !props.closedFilter ? "close" : "reopen";
    } else {
      action = !props.archiveFilter ? "archive" : "unarchive";
    }
    if (action === "archive") {
      saveIdsForModal({
        runWorkflow: true,
        selectedThreads:
          tab === "people" ? { [dataPayload.id]: dataPayload } : {},
        selectedGroups:
          tab === "groups" ? { [dataPayload.id]: dataPayload } : {},
        needRouting: false,
        group: tab === "groups",
        selectedSubThreads: {},
        isGroupTag: isGroupTag,
      });
      props.history.push(`${history.location.pathname}#modal-archive`);
    } else if (action === "unarchive") {
      onUnarchiveToggle(true)();
    } else if (action === "close") {
      onCloseToggle(true)();
    } else if (action === "reopen") {
      onReopenToggle(true)();
    }
  };

  const handleIconTooltip = (tooltip) => () => {
    setIconTooltip(tooltip);
  };

  const isWorkflowInProgress = () => {
    return (
      archivalProgress ||
      (unarchiveThis && unArchivalProgress) ||
      (deleteThis && deletionProgress) ||
      (closeThis && closingProgress) ||
      (reopenThis && reopeningProgress) ||
      contactDeleteProgress ||
      batchDeleteProgress
    );
  };

  const dataPayload = getDataPayload({
    tab,
    group: props.group,
    contacts: props.contacts,
  });

  const handleDeleteTag = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(`#modal-delete-tag/${props.group.id}`);
    },
    [history, props.group]
  );

  const syncWayIsOneWay = useCallback(
    () => checkIfSyncWayIsOneWay(props.group),
    [props.group]
  );

  useEffect(() => {
    if (closeRHSAllowed.includes(tab) && isActive) {
      toggleFullView(false);
      updateMainMenuLookOnMessages(UserMainMenuLookEnums.ICONS);
    }
  }, [tab, isActive, toggleFullView, updateMainMenuLookOnMessages]);

  useEffect(() => {
    if (
      closeRHSAllowed.includes(tab) &&
      isSendMessageWizard === true &&
      isActive
    ) {
      toggleFullView(true);
      toggleSendMessageWizard(false);
    }
  }, [
    tab,
    isActive,
    isSendMessageWizard,
    toggleFullView,
    toggleSendMessageWizard,
  ]);

  const handleOnClickNew = useCallback(
    (event) => {
      event?.preventDefault();
      event?.stopPropagation();
      if (unreadCountLocal > 0) {
        setUnreadCountLocal(0);
      }
      if (toggleSelected === 0) {
        onClickNew(url);
      }
    },
    [onClickNew, toggleSelected, unreadCountLocal, url]
  );

  const syncNowHandler = useCallback(
    (url, group) => () => {
      let groupToSync = {};
      if (group.integration === "ccb_group") {
        groupToSync = {
          key: "ccb_groups",
          query: group.name,
          ccb_groups: {
            ...group,
          },
        };
      } else if (group.integration === "ccb_process_queue") {
        groupToSync = {
          key: "ccb_processes",
          query: group.name,
          ccb_processes: {
            queues: [group],
            ...group,
          },
        };
      } else if (group.integration === "ccb_saved_search") {
        groupToSync = {
          key: "ccb_saved_searches",
          query: group.name,
          ccb_saved_searches: {
            ...group,
          },
        };
      } else if (group.integration === "pco_list") {
        groupToSync = {
          key: "pco_lists",
          query: group.name,
          pco_lists: {
            ...group,
          },
        };
      } else if (group.integration === "pco_workflow") {
        groupToSync = {
          key: "pco_workflows",
          query: group.name,
          pco_workflows: {
            ...group,
          },
        };
      } else if (group.integration === "breeze_tag") {
        groupToSync = {
          key: "breeze_tags",
          query: group.name,
          breeze_tags: {
            ...group,
          },
        };
      } else if (group.integration === "breeze_event") {
        groupToSync = {
          key: "breeze_events",
          query: group.name,
          breeze_events: {
            ...group,
          },
        };
      } else if (group.integration === "elvanto_group") {
        groupToSync = {
          key: "elvanto_groups",
          query: group.name,
          elvanto_groups: {
            ...group,
          },
        };
      } else if (group.integration === "mc_list") {
        groupToSync = {
          key: "mc_lists",
          query: group.name,
          mc_lists: {
            ...group,
          },
        };
      }
      setPrefillIntegrationGroup(groupToSync);
      history.push(url);
    },
    [history, setPrefillIntegrationGroup]
  );

  const getIntegrationName = (group) => {
    const name = group?.name || group?.group_name || group?.list_name || "";
    if (name === "") {
      return createGroupName(group);
    } else {
      return name;
    }
  };

  const content = (
    <>
      <ThreadItemAvatar
        isAllFlowsInProgress={isAllFlowsInProgress}
        isAnyFlowInProgress={isAnyFlowInProgress}
        showCheckBox={props.purpose === "new-call-modal" ? false : showCheckBox}
        checkBoxActive={checkBoxActive}
        toggleCheckboxState={toggleCheckboxState}
        selectMenuActive={selectMenuActive}
        isGroup={isGroup}
        isGroupTag={isGroupTag}
        isNewGroup={isNewGroup}
        isAdhocGroup={isAdhocGroup}
        isAllPeopleGroup={isAllPeopleGroup}
        isMultipleContacts={isMultipleContacts}
        isImportedHistory={isImportedHistory}
        isMultiplePrimaryContacts={isMultiplePrimaryContacts}
        contact={contact}
        noAvatar={props.noAvatar}
        isHideCheckbox={isHideCheckbox}
        toggleSelected={toggleSelected}
      />
      <SpanWithTooltip
        className={`detail ${
          secondRow ? "" : detailVisible ? " d-inline" : " d-none"
        } ${checkBoxActive ? "detail-active" : ""} ${
          (props.group?.sync_way?.includes("1-way") ||
            props.group?.ccb_pq_sync_way?.includes("1-way")) &&
          "flex-column"
        }`}
        title=""
        {...itemTooltipProps}
      >
        {props.purpose !== "new-call-modal" && (
          <>
            <ThreadItemTitle
              name={
                filter?.includes("never-synced")
                  ? getIntegrationName(props.group)
                  : name
              }
              unread_count={unreadCountLocal}
              purpose={props.purpose}
              boldText={boldText}
              checkBoxActive={checkBoxActive}
              createdOrSendOnUtc={createdOrSendOnUtc}
              scheduleMessage={scheduleMessage}
              userTimeZoneOffset={props.userTimeZoneOffset}
              showCheckBox={showCheckBox}
              withThreadDate={withThreadDate}
              newContactType={newContactType}
            />
            {syncWayIsOneWay() && (
              <OneWayIntegrationSyncBadge
                integrationType={
                  props.group.ccb_pq_sync_way || props.group.sync_way
                }
              />
            )}
          </>
        )}

        {/* Duplicate profile counts  */}
        {withNewDuplicateContactView && isMultipleContacts && (
          <ThreadItemDuplicateContactsProfile
            duplicateContacts={props.contacts}
          />
        )}

        {withThreadItemMessageContent && (
          <ThreadItemMessageContent
            text={text}
            withContactDetails={props.withContactDetails}
            undelivered_count={props.undelivered_count}
            reprocessed_count={props.reprocessed_unread_count}
            unread_count={unreadCountLocal}
            icon={icon}
            fromPrefix={fromPrefix}
            isSearchedThread={isSearchedThread}
            searchedQuery={searchedQuery}
            showCheckBox={showCheckBox}
            contact={contact}
            isContactUpdating={isContactUpdating}
            item_key={props.item_key}
            itemKey={props.itemKey}
            contacts_ids={props.contacts_ids}
            log={log}
            textClassName={
              withNewDuplicateContactView && isMultipleContacts ? "m-0 p-0" : ""
            }
          />
        )}
        <ThreadItemIconsContainer
          withGroupMembersCount={withGroupMembersCount}
          groupMembersCount={groupMembersCount}
          handleIconTooltip={handleIconTooltip}
          isMultipleContacts={isMultipleContacts}
          tab={tab}
          showCheckBox={showCheckBox}
          isGroup={isGroup}
          contact={contact}
          contacts={props.contacts}
          withContactDetails={props.withContactDetails}
          unread_count={unreadCountLocal}
          withIntegrationIcon={props.withIntegrationIcon}
          group={props.group}
          withUnsyncedIcon={
            props.group?.sync_way != null && props.group?.sync_state === 0
          }
          withArchiveIcon={props.withArchiveIcon}
          contactsIds={props.contacts_ids}
          log={log}
          undeliveredCount={props.undelivered_count}
          withLabelAndOrangeStatusIcon={withLabelAndOrangeStatusIcon}
          isAlreadyAdded={isAlreadyAdded}
          isAlreadyAddedButHidden={isAlreadyAddedButHidden}
          withNewDuplicateContactView={
            withNewDuplicateContactView && isMultipleContacts
          }
        />
      </SpanWithTooltip>
      {!disableAction && !filter?.includes("never-synced") && (
        <ThreadItemActionIcon
          showCheckBox={showCheckBox}
          isAllFlowsInProgress={isAllFlowsInProgress}
          tab={tab}
          filter={filter}
          contact={contact}
          onReopenToggle={onReopenToggle}
          handleArchiveOrClose={handleArchiveOrClose}
          handleDeleteTag={handleDeleteTag}
          threadFilter={props.threadFilter}
          group={props.group}
          contactFilter={props.contactFilter}
          archiveFilter={props.archiveFilter}
          contacts={props.contacts}
          closedFilter={props.closedFilter}
          voiceFilter={props.voiceFilter}
          name={name}
          isNewGroup={isNewGroup}
          isGroup={isGroup}
          groupName={groupName}
          isMultipleContacts={isMultipleContacts}
          purpose={props.purpose}
          handleNewContact={props.handleNewContact}
          newContact={props.newContact}
          showNewContactActionTooltip={showNewContactActionTooltip}
          setShowNewContactActionTooltip={setShowNewContactActionTooltip}
          currentCompany={currentCompany}
          userPermissions={userPermissions}
          isGroupTag={isGroupTag}
          onClickViewHiddenSearchResult={onClickViewHiddenSearchResult}
          thredItemNewContactPermissions={thredItemNewContactPermissions}
        />
      )}
      {filter?.includes("never-synced") && (
        <button
          className="thread-item-sync"
          onClick={syncNowHandler("#modal-new-group", props.group)}
        >
          Sync Group
        </button>
      )}
      <ThreadItemGroupSync group={props.group} />
    </>
  );
  return (
    <div className="thread-container">
      <ThreadItemClickableWrapper
        onClick={props.onClick}
        onClickNew={onClickNew ? handleOnClickNew : null}
        purpose={props.purpose}
        handleNewContact={props.handleNewContact}
        itemRef={itemRef}
        className={className}
        style={style}
        content={content}
        url={lhsGroupSelected ? "#" : url}
        filter={filter}
        isActive={props.isActive}
        hideHoverEffect={hideHoverEffect}
        toggleCheckbox={toggleCheckbox}
        replace={!!props.linkReplace}
        group={props.group}
        isGroup={isGroup}
        isNewGroup={isNewGroup}
        groupName={props.groupName}
        contacts={props.contacts}
        newContact={props.newContact}
        setShowNewContactActionTooltip={setShowNewContactActionTooltip}
        setIsHideCheckbox={setIsHideCheckbox}
      />
      {isWorkflowInProgress() && (
        // Show message if archivalProgress or unArchivalProgress or deletionProgress or closingProgress
        // or reopeningProgress or contactDeleteProgress or batchDeleteProgress
        <ThreadItemActionMessage
          archivalProgress={archivalProgress}
          unArchivalProgress={unArchivalProgress}
          deletionProgress={deletionProgress}
          closingProgress={closingProgress}
          reopeningProgress={reopeningProgress}
          contactDeleteProgress={contactDeleteProgress}
          batchDeleteProgress={batchDeleteProgress}
          isGroup={!!props.group}
        />
      )}

      {
        /* Show tooltip if archivalProgress or unArchivalProgress or deletionProgress
      or closingProgress or reopeningProgress or contactDeleteProgress or
      batchDeleteProgress */
        <ThreadItemActionWorkFlow
          tab={tab}
          dataPayload={dataPayload}
          archiveThis={archiveThis}
          onArchiveToggle={onArchiveToggle}
          unarchiveThis={unarchiveThis}
          onUnarchiveToggle={onUnarchiveToggle}
          deleteThis={deleteThis}
          onDeleteToggle={onDeleteToggle}
          closeThis={closeThis}
          onCloseToggle={onCloseToggle}
          reopenThis={reopenThis}
          onReopenToggle={onReopenToggle}
          group={props.group}
        />
      }
    </div>
  );
};

ThreadItem.propTypes = {
  handleSelected: PropTypes.func,
  groupKeyword: PropTypes.string,
  isHiddenSearchResult: PropTypes.bool,
  withArchiveIcon: PropTypes.bool,
  lhsGroupSelected: PropTypes.bool,
  withOwnGroupData: PropTypes.bool,
  withThreadItemMessageContent: PropTypes.bool,
  withThreadDate: PropTypes.bool,
  withoutTooltip: PropTypes.bool,
  withLabelAndOrangeStatusIcon: PropTypes.bool,
  isAlreadyAdded: PropTypes.bool,
  isAlreadyAddedButHidden: PropTypes.bool,
  withNewDuplicateContactView: PropTypes.bool,
};

ThreadItem.defaultProps = {
  handleSelected: () => {},
  groupKeyword: "",
  isHiddenSearchResult: false,
  withArchiveIcon: false,
  lhsGroupSelected: false,
  withOwnGroupData: false,
  withoutTooltip: false,
  withThreadItemMessageContent: true,
  withThreadDate: true,
  withLabelAndOrangeStatusIcon: false,
  isAlreadyAdded: false,
  isAlreadyAddedButHidden: false,
  withNewDuplicateContactView: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ThreadItem));
