import { useState, useEffect, useMemo } from "react";
import TelphoneRingtone from "../../assets/ringtones/telephone-ring.mp3";

export const useRingtone = () => {
  const [isReceivingCall, setIsReceivingCall] = useState(false);
  const ringtone = useMemo(() => new Audio(TelphoneRingtone), []);

  useEffect(() => {
    let volumeTimeout;
    const systemVolume = 1; // Assuming system volume is 1 (100%). Adjust if you can get the actual system volume.

    if (isReceivingCall) {
      ringtone.volume = 0.1; // Start at 10% volume
      ringtone.play();

      // After 2 seconds, set the volume to the system volume
      volumeTimeout = setTimeout(() => {
        ringtone.volume = systemVolume;
      }, 2000); // 2000ms = 2 seconds
    } else {
      ringtone.pause();
      ringtone.currentTime = 0;
      clearTimeout(volumeTimeout); // Clear timeout if call ends
    }

    // Cleanup function to stop the ringtone and clear the timeout when the component is unmounted
    return () => {
      ringtone.pause();
      ringtone.currentTime = 0;
      clearTimeout(volumeTimeout);
    };
  }, [isReceivingCall, ringtone]);

  return {
    isReceivingCall,
    setIsReceivingCall,
  };
};
