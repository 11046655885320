import { setOneToOneCallingId } from "./actions/threads";
import {
  ready,
  unsupportedBrowser,
  call,
  callStatus,
  callTtv,
  callTtvStatus,
  setIncomingCallStatus,
  setIncomingCallsParameters,
} from "./actions/twilio";
import { CANCELLED, RINGING } from "./utils/twilioHelpers";
import TelphoneRingtone from "./assets/ringtones/telephone-ring.mp3";
import { MICROPHONE_PERMISIONS_NOT_GRANTED } from "./utils/callConstants";
import { setUnsupportedMicrophonePermissionModalData } from "./actions/main";
import { isReactNativeApp } from "./helpers";
const fromEntries = require("fromentries");

const Device = require("twilio-client").Device;

export default class TwilioConfig {
  static _initialized = false;
  static _company = null;
  static _user = null;
  static _dispatchProxy = null;

  static isInitialized() {
    return TwilioConfig._initialized;
  }

  static init(token, company, user, dispatchProxy) {
    if (TwilioConfig._initialized) {
      Device.disconnectAll();
    }

    TwilioConfig._company = company;
    TwilioConfig._user = user;
    TwilioConfig._dispatchProxy = dispatchProxy;

    try {
      Device.setup(token, {
        debug: false,
        sounds: { incoming: TelphoneRingtone },
      });
    } catch (exc) {
      if (exc && exc.message && exc.message.indexOf("WebRTC") !== -1) {
        console.log("TWILIO_CONFIG: unsupported browser (no WebRTC)");
        TwilioConfig._dispatchProxy(unsupportedBrowser());
        const unsupportedModalData = {
          show: false,
        };
        TwilioConfig._dispatchProxy(
          setUnsupportedMicrophonePermissionModalData(unsupportedModalData)
        );
      }
    }

    Device.ready(function (Device) {
      TwilioConfig._dispatchProxy(ready(true));
    });

    Device.error(function (error) {
      console.log("INSIDE Twilio Client initialization error", error);
      if (error?.code === 31208 && !isReactNativeApp()) {
        const unsupportedModalData = {
          show: true,
          customMicrohphoneMessage: MICROPHONE_PERMISIONS_NOT_GRANTED,
        };
        TwilioConfig._dispatchProxy(
          setUnsupportedMicrophonePermissionModalData(unsupportedModalData)
        );
      }
    });

    Device.connect(function (conn) {
      const params = fromEntries(conn.customParameters);
      if (params.mode === "call") {
        TwilioConfig._dispatchProxy(callStatus("connected"));
      } else if (params.mode === "ttv") {
        TwilioConfig._dispatchProxy(callTtvStatus("connected"));
      }
    });

    Device.disconnect(function (conn) {
      const params = fromEntries(conn.customParameters);
      if (params.mode === "call") {
        TwilioConfig._dispatchProxy(call(null, null, null, null));
        TwilioConfig._dispatchProxy(setOneToOneCallingId(null));
      } else if (params.mode === "ttv") {
        TwilioConfig._dispatchProxy(callTtv(null, null, null, null, null));
        TwilioConfig._dispatchProxy(setOneToOneCallingId(null));
      }
      //todo: update credits count
    });

    Device.incoming(function (conn) {
      TwilioConfig._dispatchProxy(setIncomingCallStatus(RINGING));
      TwilioConfig._dispatchProxy(setIncomingCallsParameters(conn));

      conn.on("cancel", () => {
        TwilioConfig._dispatchProxy(setIncomingCallStatus(CANCELLED));
        // Handle any additional cleanup or UI updates here
      });

      conn.on("disconnect", () => {
        TwilioConfig._dispatchProxy(setIncomingCallStatus(CANCELLED));
      });
    });

    TwilioConfig._initialized = true;
  }

  static callContact(
    fromNumber,
    contactId,
    contactPhoneNumber,
    minutePrice,
    withoutSettingOneToOneCallingId
  ) {
    const params = {
      mode: "call",
      from: "+" + fromNumber,
      con_id: contactId,
      to: "" + contactPhoneNumber,
      uid: TwilioConfig._user.id,
      cid: TwilioConfig._company.id,
      CallerCountry: "US",
    };
    TwilioConfig._dispatchProxy(
      call(fromNumber, contactId, minutePrice, "connecting")
    );
    Device.connect(params);
    if (!withoutSettingOneToOneCallingId) {
      TwilioConfig._dispatchProxy(setOneToOneCallingId(contactId));
    }
  }

  static callTtvPreview(fromNumber, text, language = "en-US", voice = "man") {
    const params = {
      mode: "ttv",
      from: "+" + fromNumber,
      text,
      language,
      voice,
      uid:
        TwilioConfig._company.role === 2
          ? TwilioConfig._company.id
          : TwilioConfig._user.id,
      cid: TwilioConfig._company.id,
      CallerCountry: "US",
    };
    TwilioConfig._dispatchProxy(
      callTtv(fromNumber, text, language, voice, "connecting")
    );
    Device.connect(params);
  }

  static disconnect() {
    Device.disconnectAll();
    TwilioConfig._dispatchProxy(call(null, null, null, null));
    TwilioConfig._dispatchProxy(setOneToOneCallingId(null));
  }
}
