import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as callsActions from "../../../actions/calls";

export const useCallsStore = () => {
  const dispatch = useDispatch();
  const _calls = useSelector((store) => store.calls);

  const fetchVoicePrice = useCallback(
    (companyId, countryId) =>
      dispatch(callsActions.fetchVoicePrice(companyId, countryId)),
    [dispatch]
  );

  return {
    calls: _calls,
    fetchVoicePrice,
  };
};
