import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ModalWrapper from "../Helpers/ModalWrapper";
import Scrollbars from "react-custom-scrollbars";
import MessagesCard from "../../SendNewMessage/SendNewMessageBody/MessagesCard";
import { isNotAnEmptyArray } from "../../../utils/settingsHelpers";
import { maxMessageLength } from "../../../utils/sendMessageHelpers";
import { filterUnicodeCharacters } from "../../../utils/threadsHelpers";

const ShowUnicodeCharactersModal = (props) => {
  const { show, closeModal, messageText, messageSignature, characters } = props;

  const messageTextWithSignature = useMemo(
    () => `${messageText} ${messageSignature}`,
    [messageSignature, messageText]
  );

  const unicodeCharacters = useMemo(
    () => filterUnicodeCharacters(messageTextWithSignature),
    [messageTextWithSignature]
  );

  return (
    <ModalWrapper
      id="unicodeCharactersModal"
      show={show}
      closeModal={closeModal}
      shakeModal={closeModal}
      className="unicode-characters-modal"
    >
      <div className="unicode-characters-modal-container">
        <h1>Unicode Characters Detector</h1>
        <Scrollbars
          className=" w-100"
          autoHeight
          autoHeightMin={100}
          autoHeightMax={"70vh"}
        >
          <div>
            <h4 className="unicode-characters-modal-label">Message</h4>
            <MessagesCard>
              <div>
                <span className="unicode-characters-modal-message-text">
                  {messageText}
                </span>
                <div className="mt-4 unicode-characters-modal-signature-text">
                  {messageSignature}
                </div>
              </div>
            </MessagesCard>
            <label
              className={
                "unicode-characters-modal-characters-count d-flex align-items-center justify-content-end mt-1"
              }
            >
              Characters: {characters}/{maxMessageLength}
            </label>
          </div>
          {isNotAnEmptyArray(unicodeCharacters) && (
            <div className="mt-3 mb-3">
              <h4 className="unicode-characters-modal-label">
                Unicode Characters
              </h4>
              <MessagesCard padding={5}>
                <div className="d-flex align-items-center justify-content-start flex-wrap">
                  {unicodeCharacters.map((unicodeCharacter, idx) => (
                    <div className="unicode-character-container" key={idx}>
                      {unicodeCharacter}
                    </div>
                  ))}
                </div>
              </MessagesCard>
            </div>
          )}
        </Scrollbars>
      </div>
    </ModalWrapper>
  );
};

ShowUnicodeCharactersModal.propTypes = {
  messageText: PropTypes.string,
  messageSignature: PropTypes.string,
  characters: PropTypes.number,
};

ShowUnicodeCharactersModal.defaultProps = {
  messageText: "",
  messageSignature: "",
  characters: 0,
};

export default ShowUnicodeCharactersModal;
