import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react";
import { ReactComponent as GreenDot } from "../assets/img/icons-new/general/green-dot/green-dot.svg";
import { ReactComponent as CallAccepted } from "../assets/img/icons-new/call-accepted/call-accepted.svg";
import { ReactComponent as CallRejected } from "../assets/img/icons-new/call-rejected/call-rejected.svg";
import { ReactComponent as CallExpandIcon } from "../assets/img/icons-new/call-expand/call-expand-white.svg";
import { useRingtone } from "../utils/hooks/useRingtone";
import { useTwilioStore } from "../utils/hooks/ReduxHooks/twilioStore";
import { ACCEPTED, CANCELLED, RINGING } from "../utils/twilioHelpers";
import {
  contactName,
  formatDuration,
  isEmptyObject,
  isReactNativeApp,
} from "../helpers";
import { useComponentWillUnmount } from "../utils/hooks/useComponentWillUnmount";
import IncomingCallModal from "./modals/IncomingCallModal";
import fromEntries from "fromentries";
import { useNumbersStore } from "../utils/hooks/store/useNumbersStore";
import { isNotAnEmptyArray } from "../utils/settingsHelpers";
import { useCallsStore } from "../utils/hooks/ReduxHooks/callsStore";
import useCompaniesStore from "../utils/hooks/ReduxHooks/companiesStore";
import { useMicrophonePermissions } from "../utils/hooks/useMicrophonePermissions";
import IncomingCallDrawer from "./modals/IncomingCallDrawer";
import RenderTopNotificationBannerOverlayForRouter from "./hub/HelperComponents/RenderTopNotificationBannerOverlayForRouter";

const IncomingCallMinimizeBar = (props) => {
  const { incomingCallStatus } = props;

  const [callTimer, setCallTimer] = useState(0);
  const [creditsSpent, setCreditsSpent] = useState(0);
  const [callStartedAt, setCallStartetAt] = useState(null);
  const [showIncomingCallModal, setShowIncomingCallModal] = useState(true);

  const interval = useRef();

  // Redux store
  const { twilio, setIncomingCallStatus, setIncomingCallsParameters } =
    useTwilioStore();
  const {
    numbers: { numbers },
  } = useNumbersStore();
  const { calls, fetchVoicePrice } = useCallsStore();
  const { companies } = useCompaniesStore();
  const { incomingCallParams } = twilio;
  const { currentCompany } = companies;
  const { voicePriceByCountry } = calls;

  const { setIsReceivingCall } = useRingtone();

  const { checkMicrophonePermissions } = useMicrophonePermissions();

  const customParameters = useMemo(() => {
    if (incomingCallParams) {
      return fromEntries(incomingCallParams.customParameters);
    }
  }, [incomingCallParams]);

  const callReceivingNumberObj = useMemo(() => {
    if (isNotAnEmptyArray(numbers) && customParameters?.CalledNumber) {
      return numbers.find(
        (number) => number.number === customParameters?.CalledNumber
      );
    }

    return {};
  }, [customParameters, numbers]);

  const incomingCallFrom = useMemo(() => {
    return contactName(
      customParameters?.ContactFirstName,
      customParameters?.ContactLastName,
      incomingCallParams?.parameters?.From
    );
  }, [customParameters, incomingCallParams]);

  const creditChargeForPerMinute = useMemo(() => {
    let pricePerMinute = 0;
    if (
      !isEmptyObject(voicePriceByCountry) &&
      !isEmptyObject(callReceivingNumberObj)
    ) {
      pricePerMinute = voicePriceByCountry[callReceivingNumberObj?.country_id];
    }
    return pricePerMinute;
  }, [callReceivingNumberObj, voicePriceByCountry]);

  useEffect(() => {
    if (!isEmptyObject(callReceivingNumberObj)) {
      fetchVoicePrice(currentCompany.id, callReceivingNumberObj?.country_id);
    }
  }, [callReceivingNumberObj, currentCompany, fetchVoicePrice]);

  useEffect(() => {
    // CHeck if the browser supports microphone
    if (!isReactNativeApp()) {
      checkMicrophonePermissions();
    }
  }, [checkMicrophonePermissions]);

  useEffect(() => {
    if (incomingCallStatus === ACCEPTED) {
      interval.current = setInterval(() => {
        const time = callStartedAt
          ? Math.ceil((new Date().getTime() - callStartedAt) / 1000)
          : 0;
        const spent = creditChargeForPerMinute
          ? Math.ceil(time / 60) * creditChargeForPerMinute
          : 0;
        setCreditsSpent(spent);
        setCallTimer(time);
      }, 1000);
    }

    return () => clearInterval(interval.current);
  }, [callStartedAt, creditChargeForPerMinute, incomingCallStatus]);

  useEffect(() => {
    if (incomingCallStatus === CANCELLED) {
      setTimeout(() => {
        setIncomingCallStatus(null);
        setIncomingCallsParameters(null);
      }, 1000);
    }
  }, [incomingCallStatus, setIncomingCallStatus, setIncomingCallsParameters]);

  useEffect(() => {
    if (incomingCallStatus === RINGING) {
      setIsReceivingCall(true);
    } else {
      setIsReceivingCall(false);
    }
  }, [incomingCallStatus, setIsReceivingCall]);

  useComponentWillUnmount(() => {
    clearInterval(interval.current);
  }, []);

  const declineIncomingCalls = useCallback(() => {
    if (incomingCallStatus === ACCEPTED) {
      incomingCallParams.disconnect();
    } else {
      incomingCallParams.reject();
    }
    setIncomingCallStatus(null);
    setIncomingCallsParameters(null);
  }, [
    incomingCallParams,
    incomingCallStatus,
    setIncomingCallStatus,
    setIncomingCallsParameters,
  ]);

  const acceptIncomingCalls = useCallback(() => {
    incomingCallParams.accept();
    setIncomingCallStatus(ACCEPTED);
    setCallStartetAt(new Date().getTime());
  }, [incomingCallParams, setIncomingCallStatus]);

  const toggleIncomingCallModal = useCallback(() => {
    setShowIncomingCallModal(!showIncomingCallModal);
  }, [showIncomingCallModal]);

  const closeIncomingModalDrawer = useCallback(() => {
    setShowIncomingCallModal(false);
  }, []);

  const renderBannerText = useMemo(() => {
    if (incomingCallStatus === CANCELLED) {
      return "Call Ended";
    } else if (incomingCallStatus === ACCEPTED) {
      return (
        <span>
          <GreenDot /> On Call (Incoming) with {incomingCallFrom || ""}{" "}
          <span className="duratiion-details ml-3">
            {formatDuration(callTimer)}
          </span>
          <span>You spent {creditsSpent} credits</span>
        </span>
      );
    }
    return `Incoming call from ${incomingCallFrom || "Unknown"}`;
  }, [incomingCallStatus, incomingCallFrom, callTimer, creditsSpent]);

  return (
    <>
      <RenderTopNotificationBannerOverlayForRouter show={incomingCallStatus}>
        <div className="call-minimize-bar top-banner-wrapper">
          <div className="detail flexer-row flex-wrap">
            <span className="calling-details">{renderBannerText}</span>
          </div>
          <div className="right-action flexer-row">
            {callReceivingNumberObj?.incoming_calls_popup_enabled && (
              <CallExpandIcon
                className="mr-4 cursor-pointer"
                onClick={toggleIncomingCallModal}
                width={14}
                height={14}
              />
            )}
            {incomingCallStatus === RINGING && (
              <CallAccepted
                width={16}
                height={16}
                onClick={acceptIncomingCalls}
                className="mr-4"
              />
            )}
            <CallRejected
              width={16}
              height={16}
              onClick={declineIncomingCalls}
            />
          </div>
        </div>
      </RenderTopNotificationBannerOverlayForRouter>

      {callReceivingNumberObj?.incoming_calls_popup_enabled &&
        incomingCallStatus === RINGING && (
          <IncomingCallModal
            show={showIncomingCallModal}
            closeModal={toggleIncomingCallModal}
            declineIncomingCalls={declineIncomingCalls}
            acceptIncomingCalls={acceptIncomingCalls}
            callReceivingNumberObj={callReceivingNumberObj}
            incomingCallFrom={incomingCallFrom}
            customParameters={customParameters}
            number={incomingCallParams?.parameters?.From}
          />
        )}

      {callReceivingNumberObj?.incoming_calls_popup_enabled &&
        incomingCallStatus === ACCEPTED &&
        showIncomingCallModal && (
          <IncomingCallDrawer
            show={showIncomingCallModal}
            closeDrawer={closeIncomingModalDrawer}
            contactData={customParameters}
            name={incomingCallFrom}
            number={incomingCallParams?.parameters?.From}
            duration={formatDuration(callTimer)}
            creditsSpent={creditsSpent}
            declineCall={declineIncomingCalls}
          />
        )}
    </>
  );
};

export default IncomingCallMinimizeBar;
