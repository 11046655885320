import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as contactActions from "../../../actions/contacts";

export function useContactsStore() {
  const dispatch = useDispatch();
  const _contacts = useSelector((store) => store.contacts);

  const setPeopleFilter = useCallback(
    (filter) => dispatch(contactActions.setFilter(filter)),
    [dispatch]
  );

  const fetchCounts = useCallback(
    (companyId, fields) =>
      dispatch(contactActions.fetchCounts(companyId, fields)),
    [dispatch]
  );

  const markAsReadAllUnresolvedConflict = useCallback(
    (body) => dispatch(contactActions.markAsReadAllUnresolvedConflict(body)),
    [dispatch]
  );

  const fetchContacts = useCallback(
    (companyId, page, orderBy) =>
      dispatch(contactActions.fetchContacts(companyId, page, orderBy)),
    [dispatch]
  );

  const fetchFilteredContacts = useCallback(
    (companyId, query, countryId, countryCode, filter, page, orderBy) =>
      dispatch(
        contactActions.fetchFilteredContacts(
          companyId,
          query,
          countryId,
          countryCode,
          filter,
          page,
          orderBy
        )
      ),
    [dispatch]
  );

  const deleteContact = useCallback(
    (companyId, contactId, groupId) =>
      dispatch(contactActions.deleteContact(companyId, contactId, groupId)),
    [dispatch]
  );

  const groupRHSSelectionCleared = useCallback(
    (selection) => dispatch(contactActions.groupRHSSelectionCleared(selection)),
    [dispatch]
  );

  const addQuickContact = useCallback(
    (companyId, contactData) =>
      dispatch(contactActions.addQuickContact(companyId, contactData)),
    [dispatch]
  );

  const clearAddQuick = useCallback(
    () => dispatch(contactActions.clearAddQuick()),
    [dispatch]
  );

  return {
    contacts: _contacts,
    setPeopleFilter,
    fetchCounts,
    markAsReadAllUnresolvedConflict,
    fetchContacts,
    fetchFilteredContacts,
    deleteContact,
    groupRHSSelectionCleared,
    addQuickContact,
    clearAddQuick,
  };
}

export default useContactsStore;
